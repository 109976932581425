:root {
  --coffee-dark: #4A3428;    /* Dark roasted coffee */
  --coffee-medium: #8B5E3C;  /* Medium roast */
  --coffee-light: #BC8A5F;   /* Light roast */
  --latte-foam: #F5E6D3;     /* Latte foam color */
  --cream-beige: #E6D5C3; 
  }
  
  /* Navbar Styles */
  .custom-navbar {
    background-color: var(--latte-foam);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .nav-link-custom {
    color: var(--coffee-dark) !important;
    font-weight: 600;
    transition: transform 0.3s ease !important;
  }
  
  .nav-link-custom:hover {
    transform: scale(1.1);
  }
  
  /* Custom Button Styles */
  .btn-custom-pink {
    background-color: var(--coffee-medium);
    border: none;
    color: white;
    transition: all 0.3s ease;
  }
  
  .btn-custom-pink:hover {
    background-color: var(--coffee-dark);
    transform: translateY(-3px);
  }
  
  .btn-custom-orange {
    background-color: var(--coffee-light);
    border: none;
    color: white;
    transition: all 0.3s ease;
  }
  
  .btn-custom-orange:hover {
    background-color: var(--coffee-light);
    transform: scale(1.05);
  }
  
  /* Hero Section */
  .hero-section {
    background: linear-gradient(135deg, var(--latte-foam), var(--cream-beige));
    padding: 100px 0;
    margin-bottom: 50px;
  }
  
  /* Product Cards */
  .product-card {
    border: none;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .product-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  .product-modal .modal-content {
    background-color: var(--coffee-light);
    border-radius: 15px;
    padding: 20px;
}
.product-modal .modal-header {
  border-bottom: 1px solid var(--coffee-light);
  padding-bottom: 15px;
}


.product-modal .modal-title {
  color: var(--coffee-dark);
  font-weight: 600;
}

.product-modal .ingrediente {
  margin: 20px 0;
}

.product-modal .ingrediente h5 {
  color: var(--coffee-dark);
  margin-bottom: 15px;
  font-weight: 600;
}

.product-modal .ingrediente ul {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  list-style: none;
  padding: 0;
}

.product-modal .ingrediente li {
  padding: 8px 15px;
  background-color: rgba(var(--coffee-light-rgb), 0.1);
  border-radius: 8px;
  margin: 5px 0;
}

/* Variants Section */
.product-modal .variante {
  margin: 20px 0;
}

.product-modal .variante h5 {
  color: var(--coffee-dark);
  margin-bottom: 15px;
  font-weight: 600;
}

.product-modal .variant-row {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding: 10px;
  border-bottom: 1px solid var(--coffee-light);
}

.product-modal .variant-row:nth-child(even) {
  background-color: rgba(var(--coffee-light-rgb), 0.05);
}
.product-modal .description {
  margin: 20px 0;
  line-height: 1.6;
  color: var(--coffee-dark);
}

/* Order Information */
.product-modal .order-info {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid var(--coffee-light);
  font-style: italic;
  color: var(--coffee-medium);
}

  
  /* Animation Classes */
  .fade-up {
    animation: fadeUp 0.8s ease forwards;
  }
  
  @keyframes fadeUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .hero-banner {
    position: relative;
    height: 100vh;
    background-image: url('/public/images/BANNER.png'); /* Add your banner image */
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    overflow: hidden;
  }
  
  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      135deg,
      rgba(74, 52, 40, 0.5),    /* coffee-dark */
    rgba(139, 94, 60, 0.2)  
    );
  }
  
  .hero-content {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
  }
  
  .hero-title {
    font-size: 5rem;
    font-family: 'Pacifico', cursive;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .hero-subtitle {
    font-size: 1.8rem;
    margin-bottom: 2rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  }
  
  .hero-button {
    padding: 1rem 2.5rem;
    font-size: 1.2rem;
    border-radius: 50px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
  }
  
  .hero-button:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }
  
  /* Floating Emoji Animations */
  .floating-cupcake,
  .floating-cake,
  .floating-cookie {
    position: absolute;
    font-size: 3rem;
    animation: float 3s ease-in-out infinite;
  }
  
  .floating-cupcake {
    top: 20%;
    right: 20%;
    animation-delay: 0s;
  }
  
  .floating-cake {
    top: 30%;
    left: 20%;
    animation-delay: 0.5s;
  }
  
  .floating-cookie {
    top: 25%;
    right: 40%;
    animation-delay: 1s;
  }
  
  @keyframes float {
    0% {
      transform: translateY(0) rotate(0deg);
    }
    50% {
      transform: translateY(-20px) rotate(5deg);
    }
    100% {
      transform: translateY(0) rotate(0deg);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .hero-title {
      font-size: 3.5rem;
    }
    
    .hero-subtitle {
      font-size: 1.4rem;
    }
    
    .floating-cupcake,
    .floating-cake,
    .floating-cookie {
      font-size: 2rem;
    }
  }
  
  /* Optional: Add a bouncing scroll indicator */
  .scroll-indicator {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    animation: bounce 2s infinite;
    cursor: pointer;
    color: white;
    font-size: 2rem;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }

  .navbar-logo {
    height: 50px;  /* Adjust this value to match your logo's desired height */
    width: auto;
    transition: transform 0.3s ease;
  }
  
  /* Optional: Add a hover effect to the logo */
  .navbar-logo:hover {
    transform: scale(1.05);
  }
  
  /* Make sure the navbar has enough height for the logo */
  .custom-navbar {
    padding: 0.5rem 1rem;
    min-height: 70px;  /* Adjust based on your logo size */
  }

  /* Products Section Styles */
.products-section {
    padding: 4rem 0;
    background-color: var(--cream);
  }
  
  .products-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .products-title {
    font-family: 'Pacifico', cursive;
    color: var(--red-accent);
    margin: 0;
  }
  
  /* Custom Select Styling */
  .sorting-container {
    position: relative;
  }
  
  .custom-select {
    padding: 0.5rem 2rem 0.5rem 1rem;
    border: 2px solid var(--pink-primary);
    border-radius: 25px;
    background-color: white;
    color: var(--red-accent);
    font-weight: 500;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    background-image: url("data:image/svg+xml,..."); /* Add a custom dropdown arrow */
  }
  
  /* Product Card Styling */


.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

/* Image Container */
.product-image-wrapper {
  position: relative;
  padding-top: 100%;
  overflow: hidden;
}

.product-image,
.product-carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Overlay and Button */
.product-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(74, 52, 40, 0.2);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 2;
  padding-bottom: 2rem;
}

.product-card:hover .product-overlay {
  opacity: 1;
}

.view-button {
  position: relative;
  z-index: 3;
  padding: 0.75rem 1.5rem;
  background-color: var(--latte-foam);
  color: var(--coffee-dark);
  border: none;
  border-radius: 20px;
  font-weight: 600;
  font-size: 0.9rem;
  transform: translateY(20px);
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.product-card:hover .view-button {
  transform: translateY(0);
}

.view-button:hover {
  background-color: var(--coffee-dark);
  color: var(--latte-foam);
  transform: scale(1.05);
}

/* Product Info */
.product-category {
  display: inline-block;
  padding: 0.3rem 1rem;
  background-color: var(--cream-beige);
  color: var(--coffee-dark);
  border-radius: 15px;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.product-name {
  color: var(--coffee-dark);
  font-weight: 600;
  margin: 0;
}

/* Carousel Controls */
.product-carousel .carousel-control-prev,
.product-carousel .carousel-control-next {
  width: 15%;
  z-index: 3;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.product-carousel:hover .carousel-control-prev,
.product-carousel:hover .carousel-control-next {
  opacity: 1;
}

.product-carousel .carousel-control-prev-icon,
.product-carousel .carousel-control-next-icon {
  background-color: var(--coffee-medium);
  border-radius: 50%;
  padding: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;
}

/* Carousel Indicators */
.product-carousel .carousel-indicators {
  z-index: 3;
  margin-bottom: 0.5rem;
}

.product-carousel .carousel-indicators button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--coffee-medium);
  opacity: 0.5;
  margin: 0 4px;
}

.product-carousel .carousel-indicators button.active {
  opacity: 1;
  background-color: var(--coffee-dark);
}

.carousel-control-prev:hover .carousel-control-prev-icon,
.carousel-control-next:hover .carousel-control-next-icon {
  background-color: var(--coffee-dark);
  transform: scale(1.1);
  transition: all 0.3s ease;
}

.image-count {
  font-size: 0.8rem;
  color: var(--coffee-dark);
  display: block;
  margin-top: 0.5rem;
}

/* Responsive Adjustments */
@media (max-width: 991.98px) {
  .products-header {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .products-title {
    font-size: 2rem;
  }
}

@media (max-width: 575.98px) {
  .products-section {
    padding: 2rem 0;
  }


}

  /* Product Carousel Styles */
.product-carousel {
    height: 100%;
  }
  
  .product-carousel .carousel-inner {
    height: 100%;
  }
  
  .product-carousel .carousel-item {
    height: 100%;
  }
  
  .product-carousel .carousel-control-prev,
.product-carousel .carousel-control-next {
  width: 15%;
  z-index: 3; /* Higher z-index than overlay */
  opacity: 0;
  transition: opacity 0.3s ease;
}

.product-carousel:hover .carousel-control-prev,
.product-carousel:hover .carousel-control-next {
  opacity: 1;
}

.product-carousel .carousel-control-prev-icon,
.product-carousel .carousel-control-next-icon {
  background-color: rgba(255, 158, 205, 0.9);
  border-radius: 50%;
  padding: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;
}

/* Carousel Indicators */
.product-carousel .carousel-indicators {
  z-index: 3; /* Higher z-index than overlay */
  margin-bottom: 0.5rem;
}

.product-carousel .carousel-indicators button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--pink-primary);
  opacity: 0.5;
  margin: 0 4px;
}

.product-carousel .carousel-indicators button.active {
  opacity: 1;
}

/* Optional: Add hover effect for carousel controls */
.carousel-control-prev:hover .carousel-control-prev-icon,
.carousel-control-next:hover .carousel-control-next-icon {
  background-color: var(--red-accent);
  transform: scale(1.1);
  transition: all 0.3s ease;
}
  .image-count {
    font-size: 0.8rem;
    color: var(--pink-primary);
    display: block;
    margin-top: 0.5rem;
  }
  
  
  .product-image,
  .product-carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  



  .product-details {
    padding: 1rem;
}

.description-section {
    margin-bottom: 2rem;
    line-height: 1.6;
    color: #333;
}

.ingredients-section {
    margin-bottom: 2rem;
    padding: 1.5rem;
    background-color: var(--pink-light);
    border-radius: 10px;
}

.ingredients-section h5 {
    color: var(--red-accent);
    margin-bottom: 1rem;
    font-weight: 600;
}

.variants-section {
    border-top: 2px solid var(--pink-light);
    padding-top: 1.5rem;
}

.variants-section h5 {
    color: var(--red-accent);
    margin-bottom: 1.5rem;
    font-weight: 600;
}

.variant-item {
    margin-bottom: 1.5rem;
    padding: 1rem;
    border: 1px solid var(--pink-light);
    border-radius: 8px;
}

.variant-item h6 {
    color: var(--red-accent);
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.variant-details {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
    color: #666;
}

.variant-details span {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    background-color: var(--pink-light);
    border-radius: 4px;
    font-size: 0.9rem;
}

@media (max-width: 768px) {
    .variant-details {
        grid-template-columns: 1fr;
    }
}
.ingredients-section {
    margin-bottom: 2rem;
    padding: 1.5rem;
    background-color: var(--pink-light);
    border-radius: 10px;
}

.ingredients-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates 3 columns */
    gap: 0.75rem 1.5rem;
    padding: 0.5rem;
}

.ingredient-item {
    color: #555;
    font-size: 0.95rem;
    padding: 0.25rem 0;
}

/* Make it responsive */
@media (max-width: 768px) {
    .ingredients-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 columns on tablets */
    }
}

@media (max-width: 576px) {
    .ingredients-grid {
        grid-template-columns: 1fr; /* 1 column on mobile */
    }
}
.site-footer {
    background-color: var(--cream);
    padding: 4rem 0 2rem;
    margin-top: 4rem;
    border-top: 3px solid var(--pink-light);
}

.footer-section {
    margin-bottom: 2rem;
}

.footer-section h5 {
    color: var(--red-accent);
    font-weight: 600;
    margin-bottom: 1.5rem;
    position: relative;
    padding-bottom: 0.5rem;
}

.footer-section h5::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50px;
    height: 2px;
    background-color: var(--pink-primary);
}

.contact-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.contact-list li {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.contact-list .icon {
    color: var(--pink-primary);
    margin-right: 0.75rem;
    font-size: 1.2rem;
}

.contact-list a {
    color: #666;
    text-decoration: none;
    transition: color 0.3s ease;
}

.contact-list a:hover {
    color: var(--red-accent);
}

.social-links {
    margin-bottom: 2rem;
}

.social-icon {
    font-size: 1.5rem;
    color: var(--pink-primary);
    margin-right: 1rem;
    transition: color 0.3s ease;
}

.social-icon:hover {
    color: var(--red-accent);
}

.program {
    background-color: white;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.program h6 {
    color: var(--red-accent);
    margin-bottom: 1rem;
    font-weight: 600;
}

.program p {
    margin-bottom: 0.5rem;
    color: #666;
}

.copyright {
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 1px solid var(--pink-light);
    color: #888;
    font-size: 0.9rem;
}

@media (max-width: 768px) {
    .site-footer {
        padding: 3rem 0 1.5rem;
    }

    .footer-section {
        text-align: center;
    }

    .footer-section h5::after {
        left: 50%;
        transform: translateX(-50%);
    }

    .contact-list li {
        justify-content: center;
    }

    .social-links {
        text-align: center;
    }
}

/* Add these to your existing footer styles */

.map-container {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.map-container iframe {
    display: block;
    width: 100%;
    border-radius: 10px;
}

.contact-list a {
    color: #666;
    text-decoration: none;
    transition: color 0.3s ease;
    word-wrap: break-word;
}

.contact-list a:hover {
    color: var(--red-accent);
}

@media (max-width: 768px) {
    .map-container {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    
    .footer-section {
        margin-bottom: 2rem;
    }
}
.whatsapp-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    background-color: #25D366;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-decoration: none;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    transition: all 0.3s ease;
}

.whatsapp-button::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #25D366;
    opacity: 0.3;
    animation: pulse 2s infinite;
}

.whatsapp-icon {
    font-size: 30px;
    color: white;
    z-index: 1;
}

.whatsapp-button:hover {
    transform: scale(1.1);
    color: white;
}

.whatsapp-button:hover .tooltip {
    visibility: visible;
    opacity: 1;
    transform: translateX(-10px);
}

.tooltip {
    position: absolute;
    right: 75px;
    background-color: #333;
    color: white;
    padding: 8px 15px;
    border-radius: 5px;
    font-size: 14px;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transform: translateX(10px);
    transition: all 0.3s ease;
}

.tooltip::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -5px;
    transform: translateY(-50%);
    border-width: 5px 0 5px 5px;
    border-style: solid;
    border-color: transparent transparent transparent #333;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.3;
    }
    70% {
        transform: scale(1.4);
        opacity: 0;
    }
    100% {
        transform: scale(1.4);
        opacity: 0;
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .whatsapp-button {
        bottom: 20px;
        right: 20px;
        width: 50px;
        height: 50px;
    }

    .whatsapp-icon {
        font-size: 25px;
    }
}
/* Modal Styles */
.custom-modal .modal-content {
    border-radius: 25px;
    margin-top: 50px;
    border: none;
    background: linear-gradient(145deg, var(--cream) 0%, #fff5f8 100%);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.custom-modal .modal-header {
  position: relative;
  padding-right: 50px; /* Make space for the close button */
  border: none;
}
.custom-modal .modal-title {
  width: 100%;
  text-align: center;
}

.modal-icon {
    color: var(--coffee-dark);
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.pulse-animation {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.modal-close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: var(--coffee-dark);
  border: none;
  color: var(--latte-foam);
  font-size: 1.2rem;
  padding: 8px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 1060;
}

.modal-close-btn:hover {
  background-color: var(--coffee-medium);
  transform: rotate(90deg);
  cursor: pointer;
}

.modal-content-wrapper {
    padding: 0 2rem 2rem;
}

.story-section,
.service-card {
    background: rgba(255, 255, 255, 0.9);
    padding: 2rem;
    border-radius: 15px;
    margin-bottom: 2rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.story-section p,
.service-card p {
    color: #666;
    line-height: 1.8;
    margin-bottom: 0;
}

.values-section {
    background: var(--latte-foam);
    padding: 2rem;
    border-radius: 15px;
    margin-top: 2rem;
}

.values-section p {
    color: #666;
    line-height: 1.8;
    margin-bottom: 0;
    text-align: center;
    font-style: italic;
}

@media (max-width: 768px) {
    .modal-content-wrapper {
        padding: 0 1rem 1rem;
    }

    .story-section,
    .service-card,
    .values-section {
        padding: 1.5rem;
    }
}
.nav-link-custom {
    cursor: pointer;
}

/* If you're using react-scroll Links, also add this */
.nav-link-custom.active {
    cursor: pointer;
}
/* Update product overlay color and button positioning */


/* Update carousel controls for better visibility */
.product-carousel .carousel-control-prev,
.product-carousel .carousel-control-next {
  width: 10%; /* Smaller width for controls */
  opacity: 0.8; /* More visible by default */
  background: linear-gradient(
    to right,
    rgba(74, 52, 40, 0.5) 0%,
    rgba(74, 52, 40, 0) 100%
  );
}

.product-carousel .carousel-control-next {
  background: linear-gradient(
    to left,
    rgba(74, 52, 40, 0.5) 0%,
    rgba(74, 52, 40, 0) 100%
  );
}

.product-carousel .carousel-control-prev-icon,
.product-carousel .carousel-control-next-icon {
  background-color: var(--coffee-medium);
  border-radius: 50%;
  padding: 1rem;
  width: 2rem;
  height: 2rem;
}

/* Update carousel indicators */
.product-carousel .carousel-indicators button {
  background-color: var(--coffee-medium);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 0.5;
  margin: 0 4px;
}

.product-carousel .carousel-indicators button.active {
  background-color: var(--coffee-dark);
  opacity: 1;
}

/* Optional: Add hover effect for carousel controls */
.carousel-control-prev:hover .carousel-control-prev-icon,
.carousel-control-next:hover .carousel-control-next-icon {
  background-color: var(--coffee-dark);
  transform: scale(1.1);
}

/* Category Menu Styles */
.category-menu {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  padding: 1rem 0;
}

.category-btn {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 25px;
  background-color: var(--latte-foam);
  color: var(--coffee-dark);
  font-weight: 600;
  transition: all 0.3s ease;
  cursor: pointer;
}

.category-group {
  position: relative;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 200px;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1.5rem;
  border: none;
  background: none;
  color: var(--coffee-dark);
  text-align: left;
  transition: all 0.2s ease;
}

.dropdown-item:hover {
  background-color: var(--latte-foam);
}

.dropdown-item.active {
  background-color: var(--coffee-dark);
  color: var(--latte-foam);
}

/* Active states */
.category-btn:hover,
.category-btn.active {
  background-color: var(--coffee-dark);
  color: var(--latte-foam);
  transform: translateY(-2px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .category-menu {
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
  }

  .dropdown-menu {
      position: static;
      box-shadow: none;
      margin-top: 0.5rem;
  }
}

/* Smooth Scrolling */
html {
  scroll-behavior: smooth;
}

/* Products Grid Spacing */
.products-grid {
  margin-top: 1rem;
}

@media (max-width: 768px) {
    .menu-toggle {
        position: fixed; /* Change from sticky to fixed */
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        background: white;
        padding: 0.5rem 1rem; /* Add horizontal padding */
    }

    .category-menu {
        position: fixed; /* Change from sticky to fixed */
        top: 56px; /* Height of toggle button */
        left: 0;
        right: 0;
        background: white;
    }
}

/* Menu Toggle Button */
.menu-toggle {
    display: none; /* Hidden by default on desktop */
    width: 100%;
    background: white;
    padding: 0.5rem 0;
    margin-bottom: 1rem;
}

.toggle-btn {
    width: 100%;
    padding: 0.75rem;
    background-color: var(--coffee-medium);
    color: white;
    border: none;
    border-radius: 25px;
    font-weight: 600;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.toggle-btn:hover {
    background-color: var(--coffee-dark);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Category Menu */
.category-menu {
    width: 100%;
    background: white;
    padding: 1rem 0;
    transition: all 0.3s ease;
}

@media (max-width: 768px) {
    .menu-toggle {
        display: block;
        position: relative;
        padding: 0 1rem; /* Add horizontal padding */
    }

    .category-menu {
        position: relative;
        max-height: 500px;
        overflow-y: auto;
        padding: 1rem;
    }

    .category-menu.closed {
        max-height: 0;
        padding: 0;
        overflow: hidden;
        opacity: 0;
    }

    .category-menu.open {
        opacity: 1;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
}

.menu-container {
    margin-bottom: 1rem;
}

@media (max-width: 768px) {
    .menu-container {
        margin-bottom: 0.5rem;
    }
}

.product-price-info {
  margin-top: 0.5rem;
  font-weight: 500;
}

.product-price-info .price {
  color: var(--coffee-dark);
  font-size: 1.1rem;
}

.product-price-info .weight {
  color: #666;
  font-size: 0.9rem;
}

.variant-item {
  background: #f8f9fa;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
}

.variant-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.ingredients-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 0.5rem;
  margin: 1rem 0;
}

.ingredient-item {
  padding: 0.5rem;
  background: #f8f9fa;
  border-radius: 4px;
  font-size: 0.9rem;
}

.order-info-section {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #dee2e6;
}


.contact-form-modal .modal-header {
  background-color: var(--coffee-dark);
  color: var(--coffee-light);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.contact-form-modal .modal-header .close {
  color: white;
}

.contact-form-modal .selected-product {
  padding: 10px;
  background-color: var(--coffee-lightest);
  border-radius: 8px;
  font-size: 0.9rem;
}

.contact-form-modal .form-label {
  font-weight: 500;
  color: #444;
}

.contact-form-modal .form-control {
  border-radius: 8px;
}

.contact-form-modal .form-control:focus {
  border-color: var(--coffee-light);
  box-shadow: 0 0 0 0.2rem rgba(255, 105, 180, 0.25);
}

.btn-custom-coffee {
  background-color: var(--coffee-medium);
  border-color: var(--coffee-dark);
  color: var(--coffee-light);
}

.btn-custom-coffee:hover {
  background-color: var(--coffee-dark);
  border-color: var(--coffee-dark);
  color: var(--coffee-light);
}

.selected-product {
  padding: 10px;
  background-color: var(--coffee-lightest);
  border-radius: 8px;
}

/* Modal backdrop blur effect */
.modal-backdrop {
  background-color: rgba(75, 54, 33, 0.7);
  backdrop-filter: blur(8px);
  z-index: 1040;
}

/* Modal styling */
/* Contact modal */
.contact-form-modal {
  z-index: 1050 !important;
}
.contact-form-modal .modal-content {
  background-color: var(--coffee-light);
  border-radius: 15px;
  border: none;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  opacity: 1 !important;
}

.contact-form-modal .modal-header {
    background-color: var(--coffee-dark);
    color: var(--coffee-light);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 1.5rem;
    border-bottom: 2px solid var(--coffee-medium);
}

/* Ensure no other modals are visible */
.modal:not(.contact-form-modal) {
  display: none !important;
}
.contact-form-modal .modal-title {
    font-size: 1.5rem;
    font-weight: 600;
}

.contact-form-modal .modal-body {
    padding: 2rem;
}

.contact-form-modal .selected-product {
    padding: 1rem;
    background-color: var(--coffee-lightest);
    border: 2px solid var(--coffee-light);
    border-radius: 10px;
    font-weight: 500;
    color: var(--coffee-dark);
    margin: 1rem 0;
}

.btn-custom-coffee {
    background-color: var(--coffee-medium);
    border-color: var(--coffee-dark);
    color: var(--coffee-light);
    padding: 0.8rem 1.5rem;
    font-size: 1.1rem;
    font-weight: 500;
    transition: all 0.3s ease;
}

.btn-custom-coffee:hover {
    background-color: var(--coffee-dark);
    border-color: var(--coffee-dark);
    color: var(--coffee-light);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.btn-custom-coffee:active {
    transform: translateY(0);
}

/* Close button styling */
.contact-form-modal .modal-header .close {
    color: var(--coffee-light);
    opacity: 1;
    transition: opacity 0.3s ease;
}

.contact-form-modal .modal-header .close:hover {
    opacity: 1;
}

/* Text styling */
.contact-form-modal p {
    color: var(--coffee-dark);
    font-size: 1.1rem;
    line-height: 1.5;
}

/* Responsive adjustments */
@media (max-width: 576px) {
    .contact-form-modal .modal-body {
        padding: 1.5rem;
    }

    .contact-form-modal .modal-title {
        font-size: 1.3rem;
    }

    .btn-custom-coffee {
        padding: 0.7rem 1.2rem;
        font-size: 1rem;
    }
}

/* Modal backdrop - this creates the blur effect on the background */


/* Modal content */


/* Rest of your modal styling remains the same */

/* Modal backdrop */

/* Modal Container */
.modal-dialog {
  margin: 1.75rem auto;
  max-height: 90vh;
  display: flex;
  align-items: center;
}

/* Product Modal Specific */
.product-modal .modal-dialog {
  max-width: 800px;
  margin: 1.75rem auto;
}

.product-modal .modal-content {
  max-height: 85vh;
  overflow-y: auto;
  background-color: var(--coffee-lightest);
  border-radius: 15px;
  padding: 20px;
}

/* Ensure modal is vertically centered */
.modal {
  display: flex !important;
  align-items: center;
  padding: 1rem;
}

/* Prevent content from being cut off */
.modal-body {
  overflow-y: auto;
  max-height: calc(85vh - 200px);
  padding: 1rem;
}


/* Center the modal dialog */
.contact-form-modal .modal-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  margin: 0;
  width: 90%;
  max-width: 500px;
  z-index: 1050;
}

/* Modal content 

/* Make sure modal is using the full height of viewport */
.modal-open .modal {
    display: flex !important;
    align-items: center;
    height: 100vh;
}